import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Row, Col,
} from 'antd';
import Image from 'src/components/Image';

import get from 'lodash/get';

const { Title, Paragraph } = Typography;

const ContactDesktop = function ({
  contactTitle,
  email,
  photo,
  shortDescription,
}) {
  return (
    <div className='contact contact-desktop'>
      <Row gutter={24}>
        <Col span={10}>
          <div className='contact__info'>
            <Title
              className='contact__title'
              level={3}
            >
              {contactTitle}
            </Title>
            <Paragraph className='contact__description'>{shortDescription}</Paragraph>
            <Paragraph className='contact__email'>{email}</Paragraph>
          </div>
        </Col>
        <Col span={14}>
          <Image
            src={get(photo, '[0].url')}
            {...get(photo, '[0]')}
            preview={false}
          />
        </Col>
      </Row>
    </div>
  );
};

ContactDesktop.propTypes = {
  contactTitle: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  photo: PropTypes.object.isRequired,
  shortDescription: PropTypes.string.isRequired,
};

export default ContactDesktop;
