import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Grid } from 'antd';
import PageLayout from 'src/components/Layout';
import useContentful from '../helpers/hooks/useContentful';
import ContactDesktop from '../components/contact/ContactDesktop';
import ContactMobile from '../components/contact/ContactMobile';

const { useBreakpoint } = Grid;

export const query = `
  {
    contact(id: "75t9CZDrQPVM0cXxHe8SMe") {
      pageHeader {
        pageTitle
        pagePhotoCollection {
          items {
            fileName
            url
            width
            height
          }
        }
      }
      email
      shortDescription
      title
    }
  }
`;

const ContactIndex = function ({ location }) {
  const { lg } = useBreakpoint();
  const { data, loading } = useContentful(query);

  const mainPhoto = useMemo(() => (
    get(data, 'contact.pageHeader.pagePhotoCollection.items') || []
  ), [data]);
  const title = get(data, 'contact.pageHeader.pageTitle') || '';

  const email = get(data, 'contact.email') || '';
  const shortDescription = get(data, 'contact.shortDescription') || '';
  const contactTitle = get(data, 'contact.title') || '';

  const renderContent = useMemo(() => (lg ? (
    <ContactDesktop
      contactTitle={contactTitle}
      email={email}
      photo={mainPhoto}
      shortDescription={shortDescription}
    />
  ) : (
    <ContactMobile
      contactTitle={contactTitle}
      email={email}
      shortDescription={shortDescription}
    />
  )), [contactTitle, email, lg, mainPhoto, shortDescription]);
  
  return (
    <PageLayout
      location={location}
      photo={mainPhoto}
      title={title}
      loading={loading}
    >
      {!loading && renderContent}
    </PageLayout>
  );
};

ContactIndex.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ContactIndex;
