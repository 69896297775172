import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

const ContactMobile = function ({
  contactTitle,
  shortDescription,
  email,
}) {
  return (
    <div className='contact'>
      <Title
        className='contact__title'
        level={3}
      >
        {contactTitle}
      </Title>
      <Paragraph className='contact__description'>{shortDescription}</Paragraph>
      <Paragraph className='contact__email'>{email}</Paragraph>
    </div>
  );
};

ContactMobile.propTypes = {
  contactTitle: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
};

export default ContactMobile;
